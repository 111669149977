// @flow
import React from 'react'
import Layout, { SEO } from '../components/Layout'

const avatar = require('../images/uwe-christian-metz.jpg')

export default function Home() {
  return (
    <Layout>
      <SEO title="Kontakt" />

      <div className="content-aside">
        <h1>Kontakt:</h1>
      </div>

      <div className="content">
        <p>
          <img
            className="imgleft"
            src={avatar}
            width="96"
            height="128"
            alt="Portrait des Architekten"
          />
          <br />
          <br />
          Uwe-Christian Metz
          <br />
          Dipl.-Ing. Architekt (TU)
          <br />
          <br />
          Finkenweg 12
          <br />
          14473 Potsdam
          <br />
          <br />
        </p>
        <br />

        <table summary="tel/fax" border="0" cellSpacing="0" cellPadding="0">
          <tbody>
            <tr align="left" valign="top">
              <td>Tel</td>
              <td>0160 - 80 78 999</td>
            </tr>
            <tr align="left" valign="top">
              <td>E-Mail&nbsp;&nbsp;</td>
              <td>
                <a
                  href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#112;&#111;&#115;&#116;&#64;&#109;&#101;&#116;&#122;&#45;&#97;&#114;&#99;&#104;&#105;&#116;&#101;&#107;&#116;&#101;&#110;&#46;&#100;&#101;"
                  title="&#69;&#45;&#77;&#97;&#105;&#108;&#32;&#97;&#110;&#32;&#65;&#114;&#99;&#104;&#105;&#116;&#101;&#107;&#116;&#32;&#85;&#119;&#101;&#45;&#67;&#104;&#114;&#105;&#115;&#116;&#105;&#97;&#110;&#32;&#77;&#101;&#116;&#122;"
                >
                  &#112;&#111;&#115;&#116;&#64;&#109;&#101;&#116;&#122;&#45;&#97;&#114;&#99;&#104;&#105;&#116;&#101;&#107;&#116;&#101;&#110;&#46;&#100;&#101;
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <br />
        <br />
        <br />
        <table summary="Lebenslauf" border="0" cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <td className="right">1964</td>
              <td className="left">geboren in Potsdam</td>
            </tr>
            <tr>
              <td className="right">1981 – 1984</td>
              <td className="left">Baufacharbeiter mit Abitur</td>
            </tr>
            <tr>
              <td className="right">1984 – 1989</td>
              <td className="left">Architekturstudium TU Dresden</td>
            </tr>
            <tr>
              <td className="right">1989 – 1993</td>
              <td className="left">Mitarbeiter CBF Ipro Berlin</td>
            </tr>
            <tr>
              <td className="right">1994 – 2006</td>
              <td className="left">Partner in Jentsch Architekten GbR</td>
            </tr>
            <tr>
              <td className="right">seit 2007</td>
              <td className="left">Metz Architekten</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Layout>
  )
}
